import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const validatePassword = (password: string) => {
  const passwordPattern =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]).{8,}$/;
  if (!passwordPattern.test(password)) {
    return 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.';
  }
  return '';
};

export const validateKeyword = (keyword: string): boolean =>
  /^[a-zA-Z\s]+$/.test(keyword);

export const processId = (id: string) => {
  return id.replaceAll('-', '');
};

export const validateConnectorId = (id: string): boolean => /^[0-9]+$/.test(id);

export const validateWebsite = (website: string) => /^.+\..+$/.test(website);

export function formatNumber(num: number): string {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(2).replace(/\.0$/, '') + 'M';
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(2).replace(/\.0$/, '') + 'K';
  } else if (num < 1_000 && num > 0) {
    return (num / 1_000).toFixed(2).replace(/\.0$/, '') + 'K';
  } else {
    return num.toString();
  }
}

export function toTitleCase(str: string): string {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase(),
  );
}

export function getFormattedDate(date?: Date): string {
  if (!date) return '';

  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    '0',
  )}-${String(date.getDate()).padStart(2, '0')}`;
}

export function getCurrencySymbol(currencyCode: string): string {
  try {
    const formatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol',
    });
    const formatted = formatter.format(1);
    const match = formatted.match(/[^\d.,\s]/g);
    return match ? match.join('') : currencyCode;
  } catch (error) {
    return currencyCode;
  }
}

export function getDiffInDays(date1: string, date2: string): number {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const diffTime = Math.abs(d2.getTime() - d1.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}
